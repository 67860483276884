<template>

  <div class="prd_edit">

    <div class="modal_user_content" v-if="edit_data">

        <h2>{{ $t('brand_manager_view_tab_products_edit_chemical_composition') }}</h2>

        <div style="clear:both">&nbsp;</div>

        <div class="two_fld">

          <div class="group50">

              <div class="label">{{ $t('product_view_features_nutrient_type') }}</div>
              <client-only>
              <n-select
                v-model:value="edit_data.type"
                filterable
                :placeholder="$t('product_view_features_nutrient_type')"
                :options="edit_data.items_type"
                value-field="id"
                label-field="name"
                @update:value="validateNutrientType()"
              />
              </client-only>

              <n-alert v-if="validationErrors.type"
                title="" 
                :show-icon="false"
                :type="validationErrors.type ? 'error' : 'default'"
               >
                {{validationErrors.type ? validationErrors.type.message : ''}}
              </n-alert>

          </div>

          <div class="group50">

              <div class="label">{{ $t('product_view_features_feeding_style') }}</div>
              <client-only>
              <n-select
                v-model:value="edit_data.style"
                filterable
                :placeholder="$t('product_view_features_feeding_style')"
                :options="edit_data.items_style"
                value-field="id"
                label-field="name"
                @update:value="validateNutrientStyle()"
              />
            </client-only>

              <n-alert v-if="validationErrors.style"
                title="" 
                :show-icon="false"
                :type="validationErrors.style ? 'error' : 'default'"
               >
                {{validationErrors.style ? validationErrors.style.message : ''}}
              </n-alert>

          </div>

        </div>

        <div class="two_fld">

          <div class="group50">

            <div class="label">pH</div>
            <n-input 
              v-model:value="edit_data.ph" 
              type="text"
              @input="validateNutrientPh" 
              :placeholder="pH" 
              :status="validationErrors.ph ? 'error' : ''"
            />

            <n-alert v-if="validationErrors.ph"
              title="" 
              :show-icon="false"
              :type="validationErrors.ph ? 'error' : 'default'"
             >
              {{validationErrors.ph ? validationErrors.ph.message : ''}}
            </n-alert>

          </div>

          <div class="group50">


          </div>

        </div>

        <div class="two_fld">

           <div class="group50">

              <n-switch v-model:value="edit_data.is_organic" :checked-value="1" :unchecked-value="2" />
              &nbsp;&nbsp;{{ $t('brand_manager_view_tab_products_nutrient_organic_nutrient') }}

           </div>

           <div class="group50">

           </div>

        </div>

        <div style="clear:both">&nbsp;</div>

        <div class="two_fld">
          
          <div class="label">{{ $t('brand_manager_view_tab_products_nutrient_macro_elements') }}</div>
          <n-table class="">
      
            <thead>
              <tr>
                <th v-for="item in edit_data.items_macro_element">{{ item.name }}</th>
              </tr>
            </thead>
      
            <tr>

              <td v-for="item in edit_data.items_macro_element">
                 <n-input 
                  v-model:value="item.val" 
                  type="text"
                  @input="validateNutrientMacroElements"
                />
              </td>

            </tr>
          
          </n-table>

        </div>

        <n-alert v-if="validationErrors.macro_elements"
          title="" 
          :show-icon="false"
          :type="validationErrors.macro_elements ? 'error' : 'default'"
         >
          {{validationErrors.macro_elements ? validationErrors.macro_elements.message : ''}}
        </n-alert>

        <div style="clear:both">&nbsp;</div>

        <div class="two_fld">

          <div class="label">{{ $t('brand_manager_view_tab_products_nutrient_micro_elements') }}</div>
          <n-table class="">
      
            <thead>
              <tr>
                <th v-for="item in edit_data.items_micro_element">{{ item.name }}</th>
              </tr>
            </thead>
      
            <tr>

              <td v-for="item in edit_data.items_micro_element">
                 <n-input 
                  v-model:value="item.val" 
                  type="text"
                  @input="validateNutrientMicroElements"
                />
              </td>

            </tr>
          
          </n-table>

        </div>

        <n-alert v-if="validationErrors.micro_elements"
          title="" 
          :show-icon="false"
          :type="validationErrors.micro_elements ? 'error' : 'default'"
         >
          {{validationErrors.micro_elements ? validationErrors.micro_elements.message : ''}}
        </n-alert>

        <div style="clear:both">&nbsp;</div>

        <div style="display: flex; gap:0.5rem">

          <UiButton
            :name="$t('universal_button_save')"
            :is-loading="isLoading"
            :type="'primary'"
            @click="saveComposition()"
            />
            
          <UiButton                
              :tag="'NuxtLink'"
              :to="'/brands-manager/products/edit/' + (edit_data.id ? edit_data.id : '0?tmp_id=' + edit_data.tmp_id)"
              :name="$t('universal_button_cancel')"
              />
              
          <span v-if="edit_data.change_cnt" class="changeset">&nbsp; {{ edit_data.change_cnt + ' ' + $t('brand_manager_view_moderation_mark')}}</span>
        </div>

    </div>

  </div>

</template>

<script setup>

import { NButton, NSelect, NInput, NAlert, useMessage, NMessageProvider, NSwitch, NTable } from 'naive-ui'
import editFuncs from '/pages/brands-manager/func/editProduct.js'
import { useI18n } from "vue-i18n"

const {$api} = useNuxtApp()
const { t } = useI18n()
const message = useMessage()
const route = useRoute();

const props = defineProps({
  edit_data: {
    type: Object
  }
});

const validationErrors = ref({})
const isLoading = ref(false)
const edit_data = ref(props.edit_data)

if(!edit_data.value.type) edit_data.value.type = [];
if(!edit_data.value.style) edit_data.value.style = [];
if(!edit_data.value.items_macro_element) edit_data.value.items_macro_element = [];
if(!edit_data.value.items_micro_element) edit_data.value.items_micro_element = [];

// init type
if(edit_data.value.items_type)
  for(var k of edit_data.value.items_type){
     if(k.name) k.name = t(k.name);
  }

// init style
if(edit_data.value.items_style)
  for(var k of edit_data.value.items_style){
     if(k.name) k.name = t(k.name);
  }


const validateNutrientType = () => {
  if(!editFuncs.validateNutrientType(edit_data.value.type)){
    validationErrors.value['type'] = { message: 'This field must have at least 1 choices' };
  }else{
    delete validationErrors.value['type'];
  }
}

const validateNutrientStyle = () => {
  if(!editFuncs.validateNutrientStyle(edit_data.value.style)){
    validationErrors.value['style'] = { message: 'This field must have at least 1 choices' };
  }else{
    delete validationErrors.value['style'];
  }
}

const validateNutrientPh = () => {
  var error = editFuncs.validateNutrientPh(edit_data.value.ph);
  if(error == 1){
    validationErrors.value['ph'] = { message: t('brand_manager_view_tab_schemes_product_nutrient_ph_error') };
  }else if(error == 2){
    validationErrors.value['ph'] = { message: t('brand_manager_view_tab_schemes_product_nutrient_ph_error') };
  }else{
    delete validationErrors.value['ph'];
  }
}

const validateNutrientMacroElements = () => {
  if(!editFuncs.validateNutrientMacroElements(edit_data.value.items_macro_element)){
    validationErrors.value['macro_elements'] = { message: t('brand_manager_view_tab_schemes_product_nutrient_input_error') };
  }else{
    delete validationErrors.value['macro_elements'];
  }
}

const validateNutrientMicroElements = () => {
  if(!editFuncs.validateNutrientMicroElements(edit_data.value.items_micro_element)){
    validationErrors.value['micro_elements'] = { message: t('brand_manager_view_tab_schemes_product_nutrient_input_error') };
  }else{
    delete validationErrors.value['micro_elements'];
  }
}

const saveComposition = () => {
  
  validateNutrientType();
  validateNutrientStyle();
  validateNutrientPh();
  validateNutrientMacroElements();
  validateNutrientMicroElements();

  if(Object.keys(validationErrors.value).length>0){
    message.error(t('brand_manager_view_generic_error'));
    return false;
  }

  var params = {};
  params.id = route.params['id'] || 0;
  params.tmp_id = edit_data.value.tmp_id || 0;
  params.type =  edit_data.value.type;
  params.style =  edit_data.value.style;
  params.ph =  edit_data.value.ph;
  params.is_organic =  edit_data.value.is_organic;
  params.items_macro_element =  edit_data.value.items_macro_element;
  params.items_micro_element =  edit_data.value.items_micro_element;

  saveCompositionSend(params);
  //console.log(params);

}

/*const saveCompositionSend = async (params) => {
  isLoading.value = true;
  await $api.postB2BSaveProductComposition(params.id, params).then(res => {
    if(res && res.status == 'success'){
      message.success(t('brand_manager_view_tab_products_save_success'));
      window.setTimeout('location.reload()', 1000);
      //if(params.id>0 || params.tmp_id>0){
        //window.setTimeout('location.reload()', 1000);
      //}else{
        //window.setTimeout(function(){document.location.href='?action=edit_item&tmp_id=' + params.tmp_id ;}, 1000);
      //}
      
      isLoading.value = false;
    }
  })
}*/

const saveCompositionSend = async (params) => {
  isLoading.value = true;
  $api.postB2BSaveProductComposition(params.id, params).then(res => {
    message.success(t('brand_manager_view_tab_products_save_success'));
    if(res && res.data.value.data.tmp_id){
      edit_data.value.tmp_id = res.data.value.data.tmp_id;
      window.history.pushState({}, null, '0?action=edit_item&tmp_id=' + res.data.value.data.tmp_id);
    }
    isLoading.value = false;
  }).catch(function (error) {
     isLoading.value = false;
     message.error(t('brand_manager_view_generic_error'));
  });
}

/*
import editFuncs from '/pages/brands-manager/func/editProduct.js'
import Utils from '/pages/brands-manager/func/utils.js'
  
export default { 
  props: {
    edit_data: {
      type: Object
    }
  },
  data() {
    return { 
      validationErrors: {},
      hasError: 0,
      isLoading: false
    }
  },
  created() {

  },
  methods: {
    showNotification: Utils.showNotification,
    showErrorNotification: Utils.showErrorNotification,
    showErrorSubNotification: Utils.showErrorSubNotification,
    validateNutrientType: editFuncs.validateNutrientType,
    validateNutrientStyle: editFuncs.validateNutrientStyle,
    validateNutrientPh: editFuncs.validateNutrientPh,
    validateNutrientMacroElements: editFuncs.validateNutrientMacroElements,
    validateNutrientMicroElements: editFuncs.validateNutrientMicroElements,
    validate(){
      this.hasError = 0;
      this.validationErrors = {};

      this.validateNutrientType();
      this.validateNutrientStyle();
      this.validateNutrientPh();
      this.validateNutrientMacroElements();
      this.validateNutrientMicroElements();
    },
    saveComposition(){

      this.validate();

      if(this.hasError){
        this.showErrorNotification('brand_manager_view_generic_error');
        return false;
      }

      var params = {};
      params.id = this.$route.params.id || 0;
      params.tmp_id = this.edit_data.tmp_id || 0;
      params.type = this.edit_data.type;
      params.style = this.edit_data.style;
      params.ph = this.edit_data.ph;
      params.is_organic = this.edit_data.is_organic;
      params.items_macro_element = this.edit_data.items_macro_element;
      params.items_micro_element = this.edit_data.items_micro_element;
  
      this.isLoading = true;

      var _this = this;
      this.$axios.post('/brands-manager/products/composition/edit/' + this.$route.params.id, params )
        .then((res) => {
           if(res.data.code == 200) {   
             this.showNotification('brand_manager_view_tab_products_save_success');

             if(this.$route.params.id>0 || params.tmp_id>0){
               window.setTimeout('location.reload()', 1000);
             }else{
               window.setTimeout(function(){document.location.href='?action=edit_item&tmp_id=' + res.data.data.tmp_id ;}, 1000);
             }
             
             this.isLoading = false;

           }else{ 
              this.showErrorNotification(); 
              this.isLoading = false;       

           }
        }).catch((error) => {
          //console.log(error);
          //console.log('FAILURE!!');
          _this.showErrorNotification();
          _this.isLoading = false;
        });

    }
  }
}*/
</script>

<style scoped>



/**/
 
</style>

<style>
 
 /* PRODUCT BRAND */

.prd_edit {
  padding: 20px;
  margin-bottom: 100px;
}

.prd_edit .nr {
  display: flex;
}

.prd_edit .nr .av {
  width: 110px;
  margin-right: 2rem;
  min-height: 110px;
  border: 2px #cecece dashed;
  border-radius: 7px;
  padding: 10px;
}

.prd_edit .nr .av img {
  width: 100%;
}

.prd_edit .nr .nm {
  width: 250px;
}

.prd_edit .nr .fup {
  position: relative;
}

.prd_edit .two_fld {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 1rem;
}

.prd_edit .two_fld .ind_sat {
  margin-bottom: 1rem!important;
}

.prd_edit .two_fld>.field {
  width: 100%;
}


.right_link {
  float: right;
  color: black;
}

.left_link {
  color: #4183C4;
}

.right_link:hover{
  color: black;
}
.changeset {
  color: #FF0000;
  display: inline-flex;
  align-items: center;
}

.words{
  float: right;
  font-weight: bold;
  margin-top: 6px;
}

.indica{
  float: left;
  width: 100%;
}

.sativa{
  float: right;
  width: 250px;
  text-align: right;
}
/* UPLOADER */


.load_box{
  background-color: #dedede;
  text-align: center;
  padding: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border-radius: 5px;
  color: gray;
  width: 100%;
}
.load_box.background{
  min-height: 200px;
}
.load_box::before{
  content: ' ';
  width: calc(100% - 1rem);
  position: absolute;
  pointer-events: none;
  border: 2px darkgrey dashed;
  height: calc(100% - 1rem);
  border-radius: 5px;
}
.load_box input{
  position: absolute!important;
  width: 100%!important;
  height: 100%!important;
}

.load_box_photos{
  background-color: #dedede;
  text-align: center;
  padding: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border-radius: 5px;
  color: gray;
  width: 100%;
}

.load_box_photos::before{
  content: ' ';
  width: calc(100% - 1rem);
  position: absolute;
  pointer-events: none;
  border: 2px darkgrey dashed;
  height: calc(100% - 1rem);
  border-radius: 5px;
}
.load_box_photos input{
  position: absolute!important;
  width: 100%!important;
  height: 100%!important;
}

/* UPLOADER PHOTOS*/

.upload_photos .item{
  float: left;
  width: 100px;
  height: 100px;
  margin-left: 10px;
}

.upload_photos .item_table{
  display: table;
  width: 100px;
  height: 100px;
}

.upload_photos .item_td{
  display: table-cell;
  width: 100px;
  height: 100px;
  vertical-align: middle;
  text-align: center;
}

.upload_photos .item img{
  display: inline-block;
  max-height: 100px;
  max-width: 100px;
}

.upload_photos .delete{
  background-color: rgb(252, 83, 83);
  display: inline-block;
  position: absolute;
  padding: 5px;
  border-radius: 15px;
  margin-top: -5px;
  margin-left: -5px;
  color: white;
  border: 1px white solid;
  cursor: pointer;
}

.two_fld{
  
}

.prd_edit .two_fld>.group50 {
  width: 50%;
}

.prd_edit .two_fld>.group50:first-child {
  padding-right: 20px;
}

.prd_edit .group50>.subgroup50 {
  width: 50%;
  float: left;
}

.prd_edit .group50>.subgroup50:first-child {
  padding-right: 10px;
}

.prd_edit .two_fld .select,.prd_edit .two_fld .select select{
  width: 100%!important;
}

.prd_edit .two_fld .button {
  width: 100%!important;
}



</style>